<template>
  <div class="userCenter">
    <div class="userInfo flex-row flex-al-center">
      <div class="headImg">
        <img
          src="https://img01.yzcdn.cn/upload_files/2022/05/09/FtsbKt6KzimEwA4-SEPctMiABLiy.png"
        />
      </div>
      <div class="info">
        <div class="name" v-if="studentUserInfo.classNumber">
          {{ studentUserInfo.name }}
        </div>
        <div class="login flex-center" v-else @click="toLogin">立即登录</div>
      </div>
    </div>
    <div class="menus">
      <div
        class="menuItem flex-row flex-al-center"
        v-for="item in menus"
        :key="item.text"
        @click="toUrl(item)"
      >
        <img class="icon" :src="item.img" />
        <span>{{ item.text }}</span>
        <img
          mode="heightFix"
          class="arrow position_col_center"
          src="https://img01.yzcdn.cn/upload_files/2022/03/22/Flf5oxkG7y4cCLKD5uhLQJ7KOTQP.png"
        />
      </div>
    </div>
    <StudentMenuBar active="3" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import StudentMenuBar from "@/project/smartHomeworkUserClient/component/StudentMenuBar/index.vue";
import { Options, Vue } from "vue-class-component";
import { Dialog } from "vant/lib/";

@Options({
  components: { StudentMenuBar },
  data() {
    return {
      menus: [
        {
          img: "https://img01.yzcdn.cn/upload_files/2022/04/01/Fg82CW2lmwlk45tAvLzA2n7RVvNe.png",
          url: "/smartHomeworkUserClient/userCenter/studentInfo",
          text: "学生信息",
        },
        {
          img: "https://img01.yzcdn.cn/upload_files/2022/05/10/Fmc9XcIepCF7l2iZEC5-76NOG3Ba.png",
          url: "/smartHomeworkUserClient/checkHistory/index",
          text: "批阅记录",
        },
        {
          img: "https://img01.yzcdn.cn/upload_files/2022/05/06/Fn0X_IdgYTTBZcnKybOujyxbBYwM.png",
          url: "/smartHomeworkUserClient/userCenter/messageNotify",
          text: "消息通知",
        },
        {
          img: "https://img01.yzcdn.cn/upload_files/2022/05/10/Fl5kS_NZhmJpPG0pIJ1qpiC7R4io.png",
          url: "",
          text: "作业笔记",
        },
        {
          img: "https://img01.yzcdn.cn/upload_files/2022/04/01/FrKIIOmJpqw1AIRvPqwH3D3rHwVd.png",
          url: "",
          text: "联系客服",
        },
        {
          img: "https://img01.yzcdn.cn/upload_files/2022/04/01/Frp_IE4n8lx89rdlIE8ck3qc2PsI.png",
          hUrl: "https://mp.weixin.qq.com/s/hK5DhNuG2VlzCVL_G2z6qw",
          text: "帮助",
        },
      ],
    };
  },
  computed: {
    ...mapState("smartHomeworkUserClient/user", ["studentUserInfo"]),
  },
  mounted() {
    console.log(this.studentUserInfo);
  },
  methods: {
    toUrl(item) {
      if (item.text === "作业笔记") {
        Dialog.confirm({
          title: "提示",
          message:
            "<div class='dialogMes'><img src='https://img01.yzcdn.cn/upload_files/2022/05/13/FobsCS_4oSLVAvZI7hdr9zRbdSfr.png'/>购买并绑定智慧笔后即可开放此功能！</div>",
          cancelButtonColor: "#333333",
          cancelButtonText: "我知道了",
          confirmButtonColor: "#4593FF",
          confirmButtonText: "客服咨询",
          allowHtml: true,
        })
          .then(() => {
            // on confirm
            window.location.href = `https://prd.common.leziedu.com/media/smartHomework/qiyukf-session.html?openid=${this.studentUserInfo.openid}`;
          })
          .catch(() => {
            // on cancel
          });
        return;
      }
      if (item.text === "联系客服") {
        window.location.href = `https://prd.common.leziedu.com/media/smartHomework/qiyukf-session.html?openid=${this.studentUserInfo.openid}`;
        return;
      }
      if (item.url) {
        this.$router.push({
          path: item.url,
        });
      } else if (item.hUrl) {
        window.location.href = item.hUrl;
      }
    },
    toLogin() {
      this.$router.push({
        path: "/smartHomeworkUserClient/userCenter/studentInfo",
        query: {
          login: true,
        },
      });
    },
  },
})
export default class smartHomeworkUserClientUserCenterIndex extends Vue {}
</script>

<style lang="scss">
.dialogMes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  color: #333333;
  img {
    margin-right: 4px;
    width: 17px;
    height: 17px;
    margin-left: 10px;
    margin-top: 1px;
  }
}
.userCenter {
  min-height: 100vh;
  background: #ffffff;
}
.userInfo {
  padding: 22px 16px 0 21px;

  .info {
    margin-left: 17px;
    .name {
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      color: #333333;
    }

    .number {
      margin-top: 5px;
      font-size: 13px;
      line-height: 18px;
      color: #333333;
    }
    .login {
      width: 92px;
      height: 31px;
      font-size: 13px;
      color: #ffffff;
      background: #2481ff;
      border-radius: 90px;
    }
  }

  .headImg {
    img {
      width: 70px;
      height: 70px;
    }
  }
}

.menus {
  margin-top: 44px;

  .menuItem {
    position: relative;
    width: 100%;
    padding: 16px 24px 16px 21px;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 11px;
    }

    text {
      font-size: 14px;
      color: #333333;
    }

    .arrow {
      right: 24px;
      height: 10px;
    }
  }
}
</style>
